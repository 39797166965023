<template>
  <v-row>
    <v-col md="12" lg="12" sm="12">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-account-hard-hat</v-icon>
          Funcionários
          <v-spacer></v-spacer>
          <v-text-field
            v-model="employeePagination.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o Nome do Funcionário"
            single-line
            hide-details
            @keydown.enter="buscar()"
            @click:append="buscar()"
          />
          <v-spacer></v-spacer>
          <v-switch
            v-model="employees.active"
            :label=" `Exibindo ${employees.active? 'Ativos': 'Inativos'}`"
            single-line
            hide-details
            class="mr-3"
            style="width: 220px"
            @change="buscar()"
          ></v-switch>

          <importar-csv :upload="upload" title="Funcionários"></importar-csv>

          <v-btn
            class="ml-3 btn-new"           
            
            :to="{ name: 'employeesCreate' }"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="employees.items"
          :options.sync="options"
          :server-items-length="employees.totalItems"
          item-key="id"
          class="elevation-1"
          v-if="employees"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'employee',
                    params: { id: item.id },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
          <template v-slot:item.categories="{ item }">
            <span v-for="(cat, index) in item.categories" :key="index">
              <span v-if="index != 0">, </span>
              <span>{{ cat.name }}</span>
            </span>
          </template>
        </v-data-table>

        <v-row v-if="!employees">
          <v-col sm="12">
            <v-alert :value="true" type="warning" outlined >
              Nenhum funcionário encontrado
            </v-alert>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {EMPLOYEE_SEARCH} from "@/store/actions/employees.type";
import {EMPLOYEES_RESET, SET_EMPLOYEE_PAGINATION} from "@/store/mutations/employees.type";
import {EmployeeService} from "@/common/api/employee.service";
import store from "@/store";
import {mask} from "vue-the-mask";
import {mapGetters} from "vuex";

export default {
  name: "Employees",

  directives: {mask},

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(EMPLOYEES_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([store.dispatch(EMPLOYEE_SEARCH)]).then(() => {
      next();
    });
  },

  data: () => ({
    
    options: {},
    headers: [
      {text: "Nome", value: "name", width: 150},
      {text: "CPF", value: "cpf", width: 150},
      {text: "Categoria", value: "categories", width: 150},
      {
        text: "Visualizar",
        value: "actions",
        sortable: false,
        width: 150,
        align: "center",
      },
    ],
    result: new Promise((resolve) => {
      resolve();
    }),
  }),

  methods: {
    async upload(file) {
      return EmployeeService.importCSV(file).then((res) => {
        Promise.all([store.dispatch(EMPLOYEE_SEARCH)]);
        return res;
      });
    },
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options

      store.commit(SET_EMPLOYEE_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(EMPLOYEE_SEARCH);
    }
  },
  components: {
    "importar-csv": () => import("@/components/ImportarCSV"),
  },
  watch: {
    options: {
      handler() {
        this.buscar()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["employees", "checkUsers","employeePagination"]),
  },
};
</script>
