var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"md":"12","lg":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-hard-hat")]),_vm._v(" Funcionários "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","placeholder":"Informe o Nome do Funcionário","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscar()},"click:append":function($event){return _vm.buscar()}},model:{value:(_vm.employeePagination.search),callback:function ($$v) {_vm.$set(_vm.employeePagination, "search", $$v)},expression:"employeePagination.search"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mr-3",staticStyle:{"width":"220px"},attrs:{"label":("Exibindo " + (_vm.employees.active? 'Ativos': 'Inativos')),"single-line":"","hide-details":""},on:{"change":function($event){return _vm.buscar()}},model:{value:(_vm.employees.active),callback:function ($$v) {_vm.$set(_vm.employees, "active", $$v)},expression:"employees.active"}}),_c('importar-csv',{attrs:{"upload":_vm.upload,"title":"Funcionários"}}),_c('v-btn',{staticClass:"ml-3 btn-new",attrs:{"to":{ name: 'employeesCreate' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1),(_vm.employees)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.employees.items,"options":_vm.options,"server-items-length":_vm.employees.totalItems,"item-key":"id","footer-props":{
                      'items-per-page-options': [10, 25, 50, 75, 100]
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#3D1159","icon":"","to":{
                  name: 'employee',
                  params: { id: item.id },
                }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}},{key:"item.categories",fn:function(ref){
                var item = ref.item;
return _vm._l((item.categories),function(cat,index){return _c('span',{key:index},[(index != 0)?_c('span',[_vm._v(", ")]):_vm._e(),_c('span',[_vm._v(_vm._s(cat.name))])])})}}],null,false,3395277015)}):_vm._e(),(!_vm.employees)?_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-alert',{attrs:{"value":true,"type":"warning","outlined":""}},[_vm._v(" Nenhum funcionário encontrado ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }